import { axiosInstance } from "../../../../api/Axios";

const fetchEnvVariables = async (url) => {
  try {
    const response = await axiosInstance.request({
      method: "GET",
      url,
      headers: {
        "content-type": "text/plain; charset=utf-8",
      },
    });
    return response;
  } catch (error) {
    console.error("Error in decrypt API", error);
    throw error;
  }
};
const validEnvs = ["dev", "qa", "prod"];

const fetchAPICData = async (envData) => {
  const body = new URLSearchParams();
  body.append("client_secret", envData?.auth_APIC_client_secret);
  body.append("client_id", envData?.auth_APIC_client_id);
  body.append("grant_type", "client_credentials");
  try {
    const response = await axiosInstance.request({
      method: "POST",
      url: `${envData?.auth_APIC}/token`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
      data: body,
    });
    return response?.data?.access_token;
  } catch (error) {
    console.error("Error fetching env data:", error);
  }
};

export const fetchEnvData = async () => {
  try {
    localStorage.removeItem("token");
    let envData = await fetchEnvVariables("/env.json");
    const loadLocal = !validEnvs.includes(envData?.data?.env);
    let getToken ='';
    if (loadLocal) {
      // for localhost
      envData = await fetchEnvVariables("/env-local.json");
    }else{
        getToken = await fetchAPICData(envData?.data);
    }
      return { env: { base_url: envData?.data?.base_url, token: getToken } };
  } catch (error) {
    console.log("Error getting Env: ", error);
    throw error;
  }
};

export const decryptUserDetailsApi = async (payload) => {
  const newEncryptedId = payload?.args?.replace(/ /g, "+");
  return await axiosInstance
    .request(
      {
        method: "POST",
        url: `${payload?.env?.base_url}/cora/decrypt-user`,
        headers: {
          "content-type": "text/plain; charset=utf-8",
        },
        data: newEncryptedId,
      },
      { token: payload?.env?.token }
    )
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      console.error("Error in decrypt API", error);
      throw error;
    });
};

export const opcoRequestApi = async (requestData) => {
  return await axiosInstance
    .request({
      method: "POST",
      url: `${requestData?.env?.base_url}/opco/requests`,
      data: requestData,
    })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      console.error("Error in opco/request API", error);
      throw error;
    });
};

export const styleOfBusinessApi = async (requestData) => {
  try {
    const response = await axiosInstance.request({
      method: "GET",
      url: `${requestData?.env?.base_url}/style-of-business-and-states`,
    });
    return response;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};
