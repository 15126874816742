import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetForm } from "../fillForm/redux/filloutformSlice";
import { Button } from "@syscocorporation/cx-foundation-react";
import CenterView from "../../components/CenterView";
import Header from "../../components/Header";
import FileUpload from "../fileUpload/uploader";
import data from "../../../data.json";
import "./style.css";

export default function UploadCertificate({
  displycontinuebtn,
  setIsContinueEnabled,
  handleUpload,
  setFile,
}) {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const getIsUploadOnlyFlag = useSelector((state) => state.Home);
  const { is_upload_only } = getIsUploadOnlyFlag;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Callback function to update the file selection state
  const handleFileChange = (hasFile) => {
    setIsFileSelected(hasFile);
    setIsContinueEnabled(hasFile); // Enable if a file is selected or form is visible
  };

  const handleSignDocument = () => {
    dispatch(resetForm());
    navigate("/upload-form");
  };

  // Add effect to reset the continue button state when the component unmounts
  useEffect(() => {
    return () => {
      if (typeof setIsContinueEnabled === "function") {
        setIsContinueEnabled(false);
      }
    };
  }, [setIsContinueEnabled]);

  return (
    <div className="uploader-wrapper">
      <CenterView>
        <div>
          <Header className="heading1" text={data?.heading1} />
          <div className="uploadContentWrapper">
            <p
              className="upload-message-wrapper"
              data-testid="upload-message-wrapper1"
            >
              {data?.uploadMessageLine1}
            </p>
            <p className="upload-message-wrapper">
              {data?.uploadMessageLine2}{" "}
            </p>
            <p className="upload-message-wrapper">{data?.uploadMessageLine3}</p>
          </div>
          {is_upload_only ? (
            <div className="uploadOnly">
              <FileUpload
                onFileChange={handleFileChange}
                onUpload={handleUpload}
                setFile={setFile}
              />
            </div>
          ) : (
            <div className="fileUploadWrapper">
              <FileUpload
                onFileChange={handleFileChange}
                onUpload={handleUpload}
                setFile={setFile}
              />
              <div className="divider"></div>
              <Button
                className={`completeAndSignDoc ${
                  isFileSelected ? "disable" : ""
                }`}
                onClick={handleSignDocument}
                disabled={isFileSelected}
              >
                {data?.completeAndSignDoc}
              </Button>
            </div>
          )}
        </div>
      </CenterView>
    </div>
  );
}
