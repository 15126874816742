import React from "react";
import { useDispatch } from 'react-redux';
import { resetSiteRedirection } from '../Home/redux/slice';
import { useNavigate } from "react-router-dom";
import { Button } from "@syscocorporation/cx-foundation-react";
import ModalComponent from "../../components/Modal";
import data from "../../../data.json";
import "./style.css";

export default function RedirectPopUpScreen({
  setshowPopupCompCerti,
  closeisShowValue,
  redirection_link,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancelButtonClick = () => {
    navigate("/");
    closeisShowValue(false);
    dispatch(resetSiteRedirection());
  };

  const handleContinueButtonClick = () => {
    window.open(redirection_link, "_blank");
    setshowPopupCompCerti(true);
    closeisShowValue(false);
  };

  return (
    <div className="redirectModal">
      <ModalComponent title={data.RedirectPopScreenHeading}>
        <div className="redirectContinueMessage">
          {data.RedirectPopCancelMessage}
        </div>
        <div className="redirectContinueMessage">
          {data.RedirectPopContinueMessage}{" "}
        </div>
        <div className="completeApplicationMessage">
          {data.RedirectPopCompleteAppMessage}
        </div>
        <div className="redirect-screen-button-wrapper">
          <Button onClick={handleCancelButtonClick}>{data.cancelButton}</Button>
          <Button onClick={handleContinueButtonClick}>
            {data.continueButton}
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
}
