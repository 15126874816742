import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./modules/store";
import AppWithRouter from "./App";
import EnvProvider from "./providers/EnvProvider";
import { AxiosInterceptor } from "./api/Axios";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <EnvProvider>
      <Provider store={store}>
        <AxiosInterceptor>
          <AppWithRouter />
        </AxiosInterceptor>
      </Provider>
    </EnvProvider>
  </React.StrictMode>
);
