import React from "react";
import useGlobalContext from "../../../../src/providers/useGlobalContext";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./style.css";

const LoadingScreen = () => {
  const { showLoader } = useGlobalContext();
  return (
    <>
      {showLoader && (
        <div className="loading-container">
          <div className="overlay-box">
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingScreen;
