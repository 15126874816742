import React from "react";
import { Button } from "@syscocorporation/cx-foundation-react";
import ModalComponent from "../../components/Modal";
import data from "../../../data.json";
import "./style.css";
import { CoraRedirection } from "../../../utils/HomeApis";
import { useDispatch } from "react-redux";

function DownloadCertificatePopUp({ homeData, blob_data }) {
  const dispatch = useDispatch();
  const DownloadUpdatedPdf = () => {
    if (!blob_data) return;

    const blob = new Blob([blob_data], {
      type: "application/pdf",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "ttr_certificate.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      CoraRedirection({
        status: "Awaiting",
        homeData,
        dispatch,
      });
    }, 5000);
  };

  return (
    <div className="redirectModal">
      <ModalComponent title={data.downloadCertificatePopUpHeading}>
        <div className="cancel-con-btn">
          <Button onClick={DownloadUpdatedPdf}>
            {data.downloadCertificatePopUpContinueButton}
          </Button>
          <Button
            onClick={() => {
              CoraRedirection({
                status: "Awaiting",
                homeData,
                dispatch,
              });
            }}
          >
            {data.downloadCertificatePopUpCancelButton}
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
}

export default DownloadCertificatePopUp;
