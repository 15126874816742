import React from "react";
import { Input } from "@syscocorporation/cx-foundation-react";
import "./style.css";

const InputField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  disabled,
  onBlur,
  className,
  ...props
}) => {
  const inputProps = type === "date" ? { max: props.max } : {};

  return (
    <div className="form-group">
      {label && (
        <label
          htmlFor="input-field"
          style={{ fontFamily: '"myriad-pro",sans-serif', color: "#0009" }}
        >
          {label}
        </label>
      )}
      <Input
        type={type}
        value={value}
        name={name}
        className={`form-control ${className}`}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete="off"
        disabled={disabled}
        onBlur={onBlur}
        {...inputProps} // max prop for date
        {...props}
      />
    </div>
  );
};

export default InputField;