import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../modules/containers/Home";
import UploadCertificate from "../modules/containers/uploadCertificate";
import Certificate from "../modules/containers/Certificate";
import Filloutform from "../modules/containers/fillForm/Filloutform";

export default function AppRoutes({
  displycontinuebtn,
  closeisShowValue,
  isShowValue,
  getData,
  shipToStateDefaultValue,
  setIsContinueEnabled,
  handleUpload,
  setFile,
}) {
  return (
    <Routes>
      <Route
        path="/Home"
        element={
          <Home
            shipToStateDefaultValue={shipToStateDefaultValue}
            closeisShowValue={closeisShowValue}
            isShowValue={isShowValue}
          />
        }
      />
      <Route path="/" element={<Navigate to="/Home" replace />} />
      <Route path="/certificate" element={<Certificate />} />
      <Route
        path="/upload"
        element={
          <UploadCertificate
            displycontinuebtn={displycontinuebtn}
            onSubmit={getData}
            setIsContinueEnabled={setIsContinueEnabled}
            handleUpload={handleUpload}
            setFile={setFile}
          />
        }
      />
      <Route path="/upload-form" element={<Filloutform onSubmitt={getData} />} />
    </Routes>
  );
}
