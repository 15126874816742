// Create AxiosInterceptor component & axiosInstance.
import { useEffect, useState } from "react";
import useGlobalContext from "../providers/useGlobalContext";

import axios from "axios";

const axiosInstance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});
const AxiosInterceptor = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { setShowLoaderHandler } = useGlobalContext();
  let requestsCount = [];
  let requestsIndex = 0;

  const addRequest = (config) => {
    requestsIndex = requestsIndex + 1;
    config["reqIdx"] = requestsIndex;
    requestsCount.push(config);
  };
  const reqInterceptor = (config) => {
    setShowLoaderHandler(true);
    addRequest(config);

    // Get the latest token from localStorage each time the request is made
    const latestToken = localStorage.getItem("token") || "";
    if (latestToken)
      config.headers["Authorization"] = `Bearer ${JSON.parse(latestToken)}`;
    return config;
  };

  const removeRequest = (req) => {
    if (!req) return;
    setTimeout(() => {
      requestsCount = requestsCount.filter((arr) => arr.reqIdx !== req.reqIdx);
      if (!requestsCount.length) setShowLoaderHandler(false);
    }, 500);
  };

  const reqErrInterceptor = (error) => {
    removeRequest(error.config);
    return Promise.reject(error.config);
  };

  const resInterceptor = (response) => {
    removeRequest(response.config);
    return response;
  };

  const resErrInterceptor = (error) => {
    removeRequest(error.config);
    // prettier-ignore
    const { response: { status } } = error;

    if (status === 401 || status === 498) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error.response.data);
    }
  };

  useEffect(() => {
    const reqInterceptorEject = axiosInstance.interceptors.request.use(
      reqInterceptor,
      reqErrInterceptor
    );
    const resInterceptorEject = axiosInstance.interceptors.response.use(
      resInterceptor,
      resErrInterceptor
    );
    setIsLoaded(true);
    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptorEject);
      axiosInstance.interceptors.response.eject(resInterceptorEject);
    };
  }, []);

  return isLoaded ? children : null;
};

export { AxiosInterceptor, axiosInstance };
