import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert"; 
import { clearSnackbar } from "./redux/snackbarSlice";
import './SnackbarComp.css';

export default function SnackbarComp() {
    const dispatch = useDispatch();
    const { open, message, severity } = useSelector(state => state.snackbar);

    const handleClose = () => {
        dispatch(clearSnackbar());
    };

    return (
        <>
        {open && <div className="backdrop" />}
        <Snackbar
            className="custom-snackbar"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
        </>
    );
}
