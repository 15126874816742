import {
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { watcherSaga } from "./rootSaga";
import homeReducer from "./containers/Home/redux/slice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import filloutformSlice from "./containers/fillForm/redux/filloutformSlice";
import stepReducer from "./components/Footer/redux/stepSlice";
import snackbarSlice from "./containers/Snackbar/redux/snackbarSlice";

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  Home: homeReducer,
  Filloutform: filloutformSlice,
  step: stepReducer,
  snackbar: snackbarSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const actionsNotToBeLogged = [];

const logger = createLogger({
  predicate: (getState, action) =>
    !actionsNotToBeLogged.includes(action.type) &&
    process.env.NODE_ENV === "development",
});

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false })
      .concat(sagaMiddleware)
      .concat(logger),
});

sagaMiddleware.run(watcherSaga);

export default store;
