import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        open: false,
        message: '',
        severity: 'success', // Could be 'success', 'error', or 'info'
    },
    reducers: {
        Snackbar: (state, action) => {
            state.open = true;
            state.message = action.payload.message;
            state.severity = action.payload.severity || 'success';
        },
        clearSnackbar: (state) => {
            state.open = false;
            state.message = '';
        },
    }
});
export const { Snackbar, clearSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;