import React, { useState } from "react";
import { useSelector } from "react-redux";
import CenterView from "../../components/CenterView";
import Header from "../../components/Header";
import Dropdown from "../../components/Dropdown";
import InputField from "../../components/InputField";
import RedirectPopUpScreen from "../RedirectPopUpScreen";
import CertificationCompletedPopUp from "../CertificationCompletedPopUp";
import data from "../../../data.json";
import "./style.css";

export default function Home({
  shipToStateDefaultValue,
  closeisShowValue,
  isShowValue,
}) {
  const [showPopupCompCerti, setshowPopupCompCerti] = useState(false);
  const siteRedirection = useSelector((state) => state.Home);

  return (
    <div>
      <CenterView>
        {isShowValue && (
          <RedirectPopUpScreen
            setshowPopupCompCerti={setshowPopupCompCerti}
            closeisShowValue={closeisShowValue}
            redirection_link={siteRedirection?.redirection_link}
          />
        )}
        {showPopupCompCerti && (
          <CertificationCompletedPopUp homeData={siteRedirection} />
        )}
        <Header className="heading1" text={data?.heading1} />
        <div className="contentWrapper">
          <div className="businessStyle">
            <div className="businessStyleHeading">
              {data?.businessStyleHeading}
            </div>
            <Dropdown />
          </div>
          {shipToStateDefaultValue && (
            <div className="shipToStateStyle">
              <div className="shipToStateHeading">
                {data?.shipToStateHeading}
              </div>
              <InputField
                type="text"
                name="shipToState"
                className="shipToState"
                value={shipToStateDefaultValue}
                disabled
              ></InputField>
            </div>
          )}
        </div>
      </CenterView>
    </div>
  );
};
