import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import "@syscocorporation/cx-foundation-react/dist/main.css";
import data from "../../../data.json";
import "./uploader.css";
import { Snackbar } from "../Snackbar/redux/snackbarSlice";
import { useDispatch } from "react-redux";

const FileUpload = ({ onFileChange, setFile }) => {
  const [file, setLocalFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const validFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/tiff",
      ];

      const file = Math.round(selectedFile?.size / 1024);
      if (file >= 1024) {
        setErrorMessage(
          "Uploaded file Size greater than specifications. Please upload a valid file size."
        );
        dispatch(Snackbar({ message: "Error!", severity: "error" }));
        onFileChange(false); // Notify parent that no valid file is selected
        return;
      }
      if (validFileTypes.includes(selectedFile.type)) {
        setLocalFile(selectedFile);
        setFile(selectedFile);
        setErrorMessage("");
        onFileChange(true); // Notify parent that a file is selected
        dispatch(Snackbar({ message: "Document Saved Successfully!", severity: "success" }));
      } else {
        setErrorMessage(
          "Invalid file type. Please upload a PDF or Image file."
        );
        onFileChange(false); // Notify parent that no valid file is selected
      }
    }
  };

  const handleDelete = () => {
    setLocalFile(null);
    setFile(null);
    setErrorMessage("");
    onFileChange(false); // Notify parent that the file has been removed
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <div className="upload-container-wrapper">
      <div
        className="upload-container"
        onClick={triggerFileInput}
        data-testid="upload-container"
      >
        {!file ? (
          <>
            <div className="upload-icon">
              <CloudUploadIcon fontSize="large" />
            </div>
            <div className="upload-text">
              <span>
                {data.uploadDocument}{" "}
                <span className="browse">{data.browse}</span>
              </span>
            </div>
            <input
              type="file"
              id="file-upload"
              hidden
              data-testid="file-input"
              onChange={handleFileChange}
              accept="application/pdf,image/jpeg,image/png,image/tiff"
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </>
        ) : (
          <div>
            <div className="upload-success-icon">
              <CloudDoneIcon sx={{ fontSize: 40 }} />
            </div>
            <p className="sucessMessage" data-testid="sucessMessage">
              {data.documentSavedMessage}
              <br />
              {data.continueMessage}
            </p>
          </div>
        )}
      </div>
      {!file && (
        <div className="validFileTypesText">
          <div>{data.validFileTypesText}</div>
          <div>{data.validFileSizeText}</div>
        </div>
      )}
      {file && (
        <div className="file-actions">
          <span className="file-name">{file.name}</span>
          <div
            className="modal-icon"
            data-testid="delete-icon"
            onClick={handleDelete}
            style={{ cursor: "pointer" }}
          >
            <DeleteIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
