import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import InputField from "../../components/InputField";
import CenterView from "../../components/CenterView";
import Header from "../../../modules/components/Header";
import { nextStep } from "../../components/Footer/redux/stepSlice";
import { setFormData } from "./redux/filloutformSlice";
import { formValidation } from "./validationSchema/formValidation";
import data from "../../../data.json";
import "./Filloutform.css";
import moment from "moment";

const Filloutform = ({ onSubmitt }) => {
  const valueFromAPI = useSelector((state) => state.Home); // from Home redux
  const fillFormData = useSelector((state) => state.Filloutform);
  const [descriptionOfItems, setDescriptionOfItems] = useState(); // set the value for Description of items
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.step);
  const navigate = useNavigate();

  useEffect(() => {
    const getDescriptionofitemss = getDescriptionofitems(
      valueFromAPI.template_name
    );
    setDescriptionOfItems(getDescriptionofitemss);
  }, [valueFromAPI.template_name]);

  // description of items
  const getDescriptionofitems = (value) => {
    if (value === "MTC") {
      return "Food, Food Related Items, To-Go Disposables, and Wrapping Supplies for Resale/Exemption";
    } else if (value === "ST_12EC") {
      return "Non-reusable containers, cups, lids, plates, bowls, trays, boxes, sandwich and deli wrap, and take out containers and bags";
    } else if (value === "ST_4") {
      return "All Items Purchased 100% for Resale";
    } else if (value === "Texas_f" || value === "Texas_fb") {
      return "Food, Food Realted Items, To-Go Disposables, and Wrapping Supplies for Resale";
    } else {
      return "None";
    }
  };


  function getFieldValue(fillFormData, valueFromAPI) {
    if (fillFormData) {
      return fillFormData;
    } else if (valueFromAPI) {
      return valueFromAPI;
    }
    return ""; // default return value if both are falsy
  }

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        legalName: getFieldValue(
          fillFormData?.legalName,
          valueFromAPI.legal_name
        ),
        streetAdress: getFieldValue(
          fillFormData?.streetAdress,
          valueFromAPI.shipping_address[0]?.ship_to_address_1
        ),
        city: getFieldValue(
          fillFormData?.city,
          valueFromAPI.shipping_address[0]?.ship_to_city
        ),
        state: getFieldValue(fillFormData?.state, valueFromAPI.state),
        zip: getFieldValue(
          fillFormData?.zip,
          valueFromAPI.shipping_address[0]?.ship_to_zip
        ),
        description: getFieldValue(
          fillFormData?.description,
          valueFromAPI.style_of_business
        ),
        reason: getFieldValue(fillFormData?.reason, ""),
        discriptionofitems: getFieldValue(
          fillFormData?.discriptionofitems,
          descriptionOfItems
        ),
        stateTaxId: getFieldValue(
          fillFormData?.stateTaxId,
          valueFromAPI.sales_tax_id
        ),
        localTaxId: getFieldValue(fillFormData?.localTaxId, ""),
        email: getFieldValue(fillFormData?.email, valueFromAPI.email),
        phonenumber: getFieldValue(
          fillFormData?.phonenumber,
          valueFromAPI.company_phone?.replace(/\s/g, "")
        ),
         date: getFieldValue(fillFormData?.date, moment().format("MM/DD/YYYY")),
        name: getFieldValue(fillFormData?.name, ""),
        title: getFieldValue(fillFormData?.title, ""),
      },
      enableReinitialize: true,
      validate: (values) => formValidation(values, valueFromAPI),
      onSubmit: (values) => {
        const storeandvlaue = {
          ...values,
          activeStep,
        };
        dispatch(setFormData(storeandvlaue));
        dispatch(nextStep());
        navigate("/certificate");
      },
    });

  useEffect(() => {
    onSubmitt(handleSubmit);
  }, [onSubmitt, handleSubmit]);

  return (
    <>
      <div className="uploader-wrapper">
        <CenterView>
          <Header className="heading1 formHeading" text={data?.formHeading} />
          <form className="fill-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <InputField
                data-testid="form-input-phone-number-id"
                label="Buyer legal Company *"
                type="text"
                name="legalName"
                value={values?.legalName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Buyer legal Company"
                errors={errors?.legalName}
              ></InputField>
              {errors?.legalName && touched?.legalName && (
                <p className="errorMessage">{errors?.legalName}</p>
              )}
            </div>
            <div className="form-row">
              <InputField
                label="Buyer Street Adress *"
                type="text"
                name="streetAdress"
                value={values?.streetAdress}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Buyer Street Adress"
              ></InputField>
              {errors?.streetAdress && touched?.streetAdress && (
                <p className="errorMessage">{errors?.streetAdress}</p>
              )}
            </div>
            <div className="form-row-full">
              <div className="form-groups">
                <InputField
                  label="Buyer City *"
                  type="text"
                  name="city"
                  value={values?.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Buyer City"
                ></InputField>
                {errors?.city && touched?.city && (
                  <p className="errorMessage">{errors?.city}</p>
                )}
              </div>
              <div className="form-groups">
                <InputField
                  label="Buyer State *"
                  type="text"
                  name="state"
                  value={values?.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Buyer State"
                ></InputField>
                {errors?.state && touched?.state && (
                  <p className="errorMessage">{errors?.state}</p>
                )}
              </div>
              <div className="form-groups">
                <InputField
                  label="Buyer Zip *"
                  type="text"
                  name="zip"
                  value={values?.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Buyer Zip"
                ></InputField>
                {errors?.zip && touched?.zip && (
                  <p className="errorMessage">{errors?.zip}</p>
                )}
              </div>
            </div>
            <div className="form-row">
              <InputField
                label="Description of Business *"
                type="text"
                name="description"
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Description of Business"
              ></InputField>
              {errors?.description && touched?.description && (
                <p className="errorMessage">{errors?.description}</p>
              )}
            </div>
            {values.description.toLowerCase() === "other" && (
              <div className="form-row">
                <InputField
                  label="If Other was Chosen for Style of Business, Please give reason"
                  type="text"
                  name="reason"
                  value={values?.reason}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  placeholder="If Other was Chosen for Style of Business, Please give reason"
                ></InputField>
              </div>
            )}

            <div className="form-row-full">
              <div className="form-groups">
                <InputField
                  label="Description of items to be Purchased *"
                  type="text"
                  name="discriptionofitems"
                  value={values?.discriptionofitems}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Description of items to be Purchased"
                ></InputField>
                {errors?.discriptionofitems && touched?.discriptionofitems && (
                  <p className="errorMessage">{errors?.discriptionofitems}</p>
                )}
              </div>
            </div>

            <div className="form-row-full">
              <div className="form-groups">
                <InputField
                  label="Buyer State Tax ID *"
                  type="text"
                  name="stateTaxId"
                  value={values?.stateTaxId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Buyer State Tax ID"
                  maxLength={11}
                  onInput={(e) => {
                    if (e.target.value.length > 11) {
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 11);
                    }
                  }}
                ></InputField>
                {errors?.stateTaxId && touched?.stateTaxId && (
                  <p className="errorMessage">{errors?.stateTaxId}</p>
                )}
              </div>

              <div
                className="form-groups"
                style={{
                  display: values?.state === "Colorado" ? "block" : "none",
                }}
              >
                <InputField
                  label="Buyer Local Tax ID (Colorado Only)"
                  type="text"
                  name="localTaxId"
                  value={values?.localTaxId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Buyer Local Tax ID (Colorado Only)"
                  maxLength={11}
                  onInput={(e) => {
                    if (e.target.value.length > 11) {
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 11);
                    }
                  }}
                ></InputField>
              </div>
            </div>
            <div className="form-row-full">
              <div className="form-groups">
                <InputField
                  label="Buyer Email *"
                  type="text"
                  name="email"
                  value={values?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                ></InputField>
                {errors?.email && touched?.email && (
                  <p className="errorMessage">{errors?.email}</p>
                )}
              </div>
              <div className="form-groups">
                <InputField
                  label="Buyer Phone Number *"
                  type="text"
                  name="phonenumber"
                  value={values?.phonenumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Buyer Phone Number"
                ></InputField>
                {errors?.phonenumber && touched?.phonenumber && (
                  <p className="errorMessage">{errors?.phonenumber}</p>
                )}
              </div>
              <div className="form-groups">
                <InputField
                  readOnly
                  label="Date *"
                  type="text"
                  name="date"
                  value={values?.date}
                ></InputField>
                {errors?.date && touched?.date && (
                  <p className="errorMessage">{errors?.date}</p>
                )}
              </div>
            </div>
            <div className="form-row-full">
              <div className="form-groups">
                <InputField
                  label="Name *"
                  type="text"
                  name="name"
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Name"
                ></InputField>
                {errors?.name && touched?.name && (
                  <p className="errorMessage">{errors?.name}</p>
                )}
              </div>
              <div className="form-groups">
                <InputField
                  label="Title *"
                  type="text"
                  name="title"
                  value={values?.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Title"
                ></InputField>
                {errors?.title && touched?.title && (
                  <p className="errorMessage">{errors?.title}</p>
                )}
              </div>
            </div>
          </form>
        </CenterView>
      </div>
    </>
  );
};

export default Filloutform;
