import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const filloutformSlice = createSlice({
    name: 'filloutform',
    initialState,
    reducers: {
        formData() {},
        setFormData: (state, action) => {
            const data = action.payload;
            return { ...state, ...data };
        },
        // Action to reset the form data and set resetState to true
        resetForm: () => initialState,
    }
});

export const { setFormData, formData, resetForm } = filloutformSlice.actions;
export default filloutformSlice.reducer;
