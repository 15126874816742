import React from 'react';
import { Logo } from '@syscocorporation/cx-foundation-react';
import CheckIcon from '@mui/icons-material/Check';
import data from '../../../data.json';
import './Navbar.css';

function Navbar({ step }) {
  const getHeadingClass = (optionStep) => (step >= optionStep ? 'navHeading active' : 'navHeading');
  const getStepClass = (optionStep) => {
    if (step > optionStep) {
      return 'step completed';
    } else if (step === optionStep) {
      return 'step active';
    } else {
      return 'step';
    }
  };
  
  const getLineClass = (optionStep) => (step > optionStep ? 'line completed' : 'line');

  return ( 
    <div className='step-navigation'>
      <div className='dflex'>
        <div className='logo'><Logo size="md" />
          <div className='portalName'>{data.portalName}</div>
        </div>
        <div className='steps'>
          {data.steps.map((stepData, index) => (
            <React.Fragment key={stepData.id}>
              <div className='options'>
                <div className={getStepClass(stepData.id)}>
                  {step > stepData.id ? <CheckIcon fontSize="small" /> : stepData.id}
                </div>
                <div className={getHeadingClass(stepData.id)} style={{ fontFamily: '"myriad-pro",sans-serif' }}>{stepData.title}</div>
              </div>
              {index < data.steps.length - 1 && <div className={getLineClass(stepData.id)}></div>}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Navbar;
