import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./modules/components/ErrorBoundary/index";
import LoadingIndicator from "./modules/components/LoadingIndicator/index";
import { setStep as setActiveStep } from "./modules/components/Footer/redux/stepSlice";
import { useDispatch, useSelector } from "react-redux";
import { homePageData } from "./modules/containers/Home/redux/slice";
import "@syscocorporation/cx-foundation-react/dist/main.css";
import Navbar from "./modules/components/navbar/Navbar";
import Footer from "./modules/components/Footer";
import AppRoutes from "./router/Routes";
import { uploadFile } from "./utils/uploadFile";
import SnackbarComp from "./modules/containers/Snackbar/SnackbarComp";
import { Snackbar } from "./modules/containers/Snackbar/redux/snackbarSlice";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function App() {
  const query = useQuery();
  const encryptedId = query.get("encryptedId");
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const location = useLocation();
  const handelSubmitRef = useRef(null);
  const [styleOfBusinessDefaultValue, setStyleOfBusinessDefaultValue] =
    useState("");
  const [shipToStateDefaultValue, setShipToStateDefaultValue] = useState("");
  const homeSelector = useSelector((state) => state.Home);
  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const pdfBlob = homeSelector.blob_data;
  const [isShowValue, setisShowValue] = useState(homeSelector.site_redirection);
  const [file, setFile] = useState(null);

  const [displayContBtn, setDisplayContBtn] = useState(); // chenge name continue Button in filloutform (Continue to E-sign)
  const displycontinuebtn = (value) => {
    setDisplayContBtn(value);
  };
  //for popup at load page
  useEffect(() => {
    if (homeSelector.site_redirection) {
      setisShowValue(homeSelector.site_redirection);
    }
  }, [homeSelector.site_redirection]);

  useEffect(() => {
    switch (location.pathname) {
      case "/Home":
        setStep(1);
        dispatch(setActiveStep(1));
        break;
      case "/upload":
      case "/upload-form":
        setStep(2);
        break;
      case "/certificate":
        setStep(3);
        break;
      default:
        setStep(1);
    }
  }, [dispatch, location.pathname]);

  const closeisShowValue = (closevalue) => {
    setisShowValue(closevalue);
  };

  const popUpDisply = (value) => {
    setisShowValue(value);
  };

  const onSubmit = () => {
    if (handelSubmitRef.current) {
      handelSubmitRef.current();
    }
  };

  useEffect(() => {
    const fetchData = () => {
      try {
        dispatch(homePageData({ args: encryptedId }));
      } catch (err) {
        console.error("error fetching data");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (homeSelector?.style_of_business && !styleOfBusinessDefaultValue) {
      setStyleOfBusinessDefaultValue(homeSelector?.style_of_business);
    }
    setShipToStateDefaultValue(homeSelector?.state);
    if (homeSelector?.error) {
      showBoundary(homeSelector?.error);
    }
  }, [homeSelector, showBoundary, styleOfBusinessDefaultValue]);

  // Validate mandatory fields
  const areMandatoryFieldsPresent = () => {
    return (
      homeSelector.email &&
      homeSelector.legal_name &&
      homeSelector.bill_to_address_1 &&
      homeSelector.bill_to_city &&
      homeSelector.bill_to_state &&
      homeSelector.bill_to_zip &&
      homeSelector.style_of_business &&
      homeSelector.opco_number &&
      homeSelector.cora_id &&
      homeSelector.owner_email &&
      homeSelector.are_you_non_profit &&
      homeSelector.registered_tribal_member &&
      homeSelector.ship_to_on_tribal_land
    );
  };

  const handleUpload = async () => {
    if (step === 2 && file) {
      if (!areMandatoryFieldsPresent()) {
        dispatch(
          Snackbar({
            message: "Please fill all mandatory fields before uploading.",
            severity: "success",
          })
        );
        return;
      }
      const response = await uploadFile(file, homeSelector);

      if (response?.status === 200) {
        dispatch(
          Snackbar({
            message:
              "File Uploaded Successfully!. Going back to Account Application.",
            severity: "success",
          })
        );
        return response;
      } else {
        dispatch(
          Snackbar({
            message: "File upload Failed. Please try again later!",
            severity: "error",
          })
        );
      }
    }
  };

  const sendBlobToAvalara = async () => {
    if (!pdfBlob) return;
    const result = await uploadFile(pdfBlob, homeSelector);
    if (result?.status === 200) {
      dispatch(
        Snackbar({
          message: "Certificate Uploaded Successfully!",
          severity: "success",
        })
      );
      return result;
    } else {
      dispatch(
        Snackbar({
          message: "Certificate upload Failed. Please try again later!",
          severity: "error",
        })
      );
    }
  };

  return (
    <>
      <Navbar step={step} />
      <AppRoutes
        displycontinuebtn={displycontinuebtn}
        closeisShowValue={closeisShowValue}
        isShowValue={isShowValue}
        getData={(submitFunc) => (handelSubmitRef.current = submitFunc)}
        shipToStateDefaultValue={shipToStateDefaultValue}
        setIsContinueEnabled={setIsContinueEnabled}
        handleUpload={handleUpload}
        setFile={setFile}
      />
      <Footer
        displayContBtn={displayContBtn}
        onClickk={onSubmit}
        popUpDisply={popUpDisply}
        isContinueEnabled={isContinueEnabled}
        continueAfterFileUploaded={handleUpload}
        sendBlobToAvalaraOnClickingContinue={sendBlobToAvalara}
      />
    </>
  );
}
function AppWithRouter() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <LoadingIndicator />
        <App />
        <SnackbarComp />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default AppWithRouter;
