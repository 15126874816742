import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, FormControl } from '@mui/material';
import { setHomePageData } from '../../containers/Home/redux/slice';
import './style.css';

const Dropdown = () => {
  const homeSelector = useSelector((state)=> state.Home);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const value = event.target.value;
    // Dispatch the new selected value to Redux
    dispatch(setHomePageData({ style_of_business: value }));
  };

  return (
    <FormControl fullWidth variant="outlined" className='select-form-control'>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={homeSelector?.style_of_business || ''}
        onChange={handleChange}
        style={{
          'text-align': 'start',
        }}
        sx={{
          width: '168px',
          height: '32px',
          fontSize: '14px',
          fontWeight: '600',
          color: '#008CD2',
          fontFamily: 'myriad-pro",sans-serif',
        }}
      >
        {homeSelector?.style_of_businesses?.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;


