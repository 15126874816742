import { createSlice } from '@reduxjs/toolkit';

//Initializing state
const initialState = {
    email: "",
    owner_email: "",
    are_you_non_profit: "",
    registered_tribal_member: "",
    ship_to_on_tribal_land: "",
    legal_name: "",
    trade_dba_name: "",
    company_phone: "",
    bill_to_address_1: "",
    bill_to_address_2: "",
    bill_to_city: "",
    bill_to_state: "",
    bill_to_zip: "",
    shipping_address: [
      {
        ship_to_address_1: "",
        ship_to_address_2: "",
        ship_to_city: "",
        ship_to_state: "",
        ship_to_zip: ""
      }
    ],
    style_of_business: "",
    federal_ein: "",
    stateTaxId: "",
    tax_exempt: "",
    opco_number: "",
    ma: "",
    ma_email_address: "",
    credit_application_name: "",
    cora_id: "",
    salesforce_id: "",
    state: "",
    location: "",
    address: "",
    template_name: "",
    legal_enitity_name: "",
    site_redirection: false,
    redirection_link: "",
    is_upload_only: false,
    blob_data: "",
  };

//Creating Reducers functions
export const homeSlice = createSlice({
  name: 'Home',
  initialState,
  reducers: {  
    homePageData: (state, action ) => {
      return {...state, ...action.payload };
    },
    setHomePageData: (state, action) => {
      return { ...state, ...action.payload };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetSiteRedirection: (state) => {
      state.site_redirection = false;
    }
  },
})

//Action creators
export const { homePageData, setHomePageData, setError, resetSiteRedirection } = homeSlice.actions;
export default homeSlice.reducer;
