import { call, put } from "redux-saga/effects";
import { setError, setHomePageData } from "./slice";
import {
  decryptUserDetailsApi,
  fetchEnvData,
  opcoRequestApi,
  styleOfBusinessApi,
} from "./request";

// Action type for opco request
export const OPCO_REQUEST = "OPCO_REQUEST";
export const STYLE_OF_BUSINESS_REQUEST = "STYLE_OF_BUSINESS_REQUEST";

export function* handleDecryptUserDetails(action) {
  try {
    const envData = yield call(fetchEnvData);
    const newToken = envData?.env?.token;
    localStorage.setItem("token", JSON.stringify(newToken));
    const response = yield call(
      decryptUserDetailsApi,
      Object.assign(action.payload, envData)
    );
    yield put(setHomePageData(Object.assign(response.data, envData)));
    // sending the complete response data for the opco request
    yield put({
      type: OPCO_REQUEST,
      payload: response.data,
    });
    yield put({
      type: STYLE_OF_BUSINESS_REQUEST,
      payload: envData,
    });
  } catch (error) {
    yield put(setError(error.error_message || "Something went wrong"));
  }
}

export function* handleOpcoRequest(action) {
  try {
    const response = yield call(opcoRequestApi, action.payload);
    if (response.status === 200) {
      if (action.callback) {
        action.callback(response.data);
      } 
      yield put(setHomePageData(response.data));
      return response.data;
    } else {
      throw new Error(response.data?.error_message || "Request failed");
    }
  } catch (error) {
    yield put(setError(error.message || "Something went wrong"));
    return Promise.reject(error);
  }
}

export function* handleStyleOfBusiness(action) {
  try {
    const response = yield call(styleOfBusinessApi, action.payload);
    yield put(setHomePageData(response.data));
  } catch (error) {
    yield put(setError(error.error_message || "Something went wrong"));
  }
}
