import { axiosInstance } from "../api/Axios";
import { Snackbar } from "../modules/containers/Snackbar/redux/snackbarSlice";

export const redirectCORAApi = async (requestData, base_url) => {
  const params = new URLSearchParams(requestData).toString();
  try {
    const response = await axiosInstance.request({
      method: "POST",
      url: `${base_url}/cora/redirect-to-cora?${params}`,
    });
    return response;
  } catch (error) {
    console.error("Error", error);
    return error;
  }
};

export const CoraRedirection = async ({ status, homeData, dispatch }) => {
  const res = await redirectCORAApi(
    {
      status,
      cora_id: homeData?.cora_id,
    },
    homeData?.env?.base_url
  );
  function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  }
  if (res?.status === 200 && validateUrl(res?.data.split("/web")[0])) {
    dispatch(
      Snackbar({
        message: " Please wait! While Redirecting to account application.",
        severity: "success",
      })
    );
    window.location = res?.data;
  } else {
    dispatch(
      Snackbar({
        message: "Redirection Failed. Please try again later!",
        severity: "error",
      })
    );
  }
};
export const checkDocUploadStatus = async (requestData) => {
  try {
    const response = await axiosInstance.request({
      method: "GET",
      url: `${requestData?.env?.base_url}/avalara/document-upload-status/coraid/${requestData?.cora_id}`,
    });
    return response;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

const generateSignatureId = () => {
  const timestamp = Date.now().toString(36);
  const randomPart = Math.random().toString(36).substring(2, 10);
  return `${randomPart.slice(0, 4)}-${randomPart.slice(4, 8)}-${timestamp.slice(
    -3
  )}`;
};

export const signatureController = async (requestData) => {
  const signature_id = generateSignatureId();

  try {
    const response = await axiosInstance.request({
      method: "POST",
      url: `${requestData?.env?.base_url}/signatures`,
      data: { cora_id: requestData?.cora_id, signature_id },
    });
    return { response, signature_id };
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};
