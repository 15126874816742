import React from 'react';
import './style.css';

const CenterView = ({ children }) => { 
  return   (
    <div className='centerView'>
      <header></header>
      <main>{children}</main>
    </div>

  )
}

export default CenterView;
