import React, { useState } from "react";
import { Button } from "@syscocorporation/cx-foundation-react";
import ModalComponent from "../../components/Modal";
import data from "../../../data.json";
import "./style.css";
import { checkDocUploadStatus, CoraRedirection } from "../../../utils/HomeApis";
import { useDispatch } from "react-redux";
import { Snackbar } from "../Snackbar/redux/snackbarSlice";

function CertificationCompletedPopUp({ homeData }) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const handleContinueButtonClick = async () => {
    const status = await checkDocUploadStatus(homeData);
    if (status?.data?.uploaded) {
      CoraRedirection({ status: "Awaiting", homeData, dispatch });
    } else {
      dispatch(
        Snackbar({
          message:
            "Certificate is not uploaded. Please try again, after the completion of upload!",
          severity: "error",
        })
      );
      setError(true);
    }
  };

  return (
    <div className="redirectModal">
      <ModalComponent title={data.certificationCompletedHeading}>
        <div className="con-btn">
          {!error && (
            <Button onClick={handleContinueButtonClick}>
              {data.continueButton}
            </Button>
          )}
          {error && (
            <>
              <h2>The Retry Button will be accessible again after 5 minutes.</h2><br/>
              <Button
                onClick={handleContinueButtonClick}
                disabled={setTimeout(() => setError(false), 500000)}
              >
                Retry
              </Button>
            </>
          )}
        </div>
      </ModalComponent>
    </div>
  );
}

export default CertificationCompletedPopUp;
