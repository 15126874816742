import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
} from "@mui/icons-material";
import { Button } from "@syscocorporation/cx-foundation-react";
import { nextStep, prevStep } from "../../components/Footer/redux/stepSlice";
import data from "../../../data.json";
import "./style.css";
import { CoraRedirection } from "../../../utils/HomeApis";
import DownloadCertificatePopUp from "../../containers/DownloadCertificatePopUp";
import { OPCO_REQUEST } from "../../containers/Home/redux/saga-handler";

function Footer({
  displayContBtn,
  popUpDisply,
  onClickk,
  isContinueEnabled,
  continueAfterFileUploaded,
  sendBlobToAvalaraOnClickingContinue,
}) {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.step);
  const navigate = useNavigate();
  const [downloadFile, setDownloadFile] = useState(false);
  const siteRedirection = useSelector((state) => state.Home);
  const location = useLocation();
  const currentStep1 = location.pathname === "/Home";
  const currentStep2 = location.pathname === "/upload";
  const currentStep3 = location.pathname === "/upload-form";
  const currentStep4 = location.pathname === "/certificate";

  const handleNext = async () => {
    dispatch(nextStep());
    // Calling the onClickk function to submit the form before moving to the next step
    if (currentStep3 && onClickk) {
      onClickk(); // Calling form submission function
    } else if (currentStep1) {
      const promise = new Promise((resolve) => {
        dispatch({
          type: OPCO_REQUEST,
          payload: siteRedirection,
          callback: resolve,
        });
      });

      promise.then((data) => {
        if (data.site_redirection) {
          popUpDisply(data.site_redirection);
          return;
        } else {
          navigate("/upload");
        }
      });
    } else if (currentStep2) {
      const checkFileUpload = await continueAfterFileUploaded();
      if (checkFileUpload?.status === 200) {
        CoraRedirection({
          status: "Awaiting",
          homeData: siteRedirection,
          dispatch,
        });
      }
      return;
    } else if (currentStep4) {
      const res = await sendBlobToAvalaraOnClickingContinue();
      if (res?.status === 200) {
        setDownloadFile(true);
      }
    }
  };

  const handlePrev = async () => {
    dispatch(prevStep());
    if (currentStep4) navigate("/upload-form");
    else if (currentStep3) navigate("/upload");
    else if (currentStep2)
      navigate(`/Home?encryptedId=${siteRedirection?.args}`);
    else if (currentStep1) {
      //call cora redirection API
      CoraRedirection({
        status: "Taxable",
        homeData: siteRedirection,
        dispatch,
      });
    }
  };

  // Check if the continue button should be enabled or disabled
  const isContinueEnabledCheck = currentStep2 ? isContinueEnabled : true;
  const buttonData = () => {
    if (currentStep3) return data.continueBtnfillForm;
    else if (currentStep4) return data.confirmBtn;
    else return data.continueButton;
  };
  return (
    <div className="footer-button-wrapper">
      <Button type="primary" className="btn-primary" onClick={handlePrev}>
        <KeyboardArrowLeftIcon className="leftArrowIcon" />
        {data.backButton}
      </Button>
      <Button
        type="primary"
        className={
          displayContBtn && (currentStep2 || activeStep === 3)
            ? "btn-primary-form"
            : "btn-primary"
        }
        onClick={handleNext}
        disabled={currentStep2 && !isContinueEnabledCheck} // Only disable if on step 2 and not enabled
      >
        {buttonData()}
        <KeyboardArrowRightIcon className="rightArrowIcon" />
      </Button>
      {downloadFile && (
        <DownloadCertificatePopUp
          homeData={siteRedirection}
          blob_data={siteRedirection?.blob_data}
        />
      )}
    </div>
  );
}

export default Footer;
