
import React from 'react';
import { ModalNew } from '@syscocorporation/cx-foundation-react';

const ModalComponent = ({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  primaryButtonText, 
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  }) => {
  return (
    <div className="fd ttr-demo-modal">
      <ModalNew
        modalType="dialog"
        isShown={isOpen}
        headingTitle={title}
        onModalClose={onClose}
        showCloseButton={false}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={onSecondaryButtonClick}
      >
        {children}
      </ModalNew>
    </div>
  );
}

export default ModalComponent;
