import { createSlice } from '@reduxjs/toolkit';

const stepSlice = createSlice({
    name: 'step',
    initialState: 1, // Assuming start at step 1
    reducers: {
        nextStep: (state) => Math.min(state + 1, 3),
        prevStep: (state) => Math.max(state - 1, 1),
        setStep: (state, action) => action.payload,
    }
});

export const { nextStep, prevStep, setStep } = stepSlice.actions;
export default stepSlice.reducer;
