import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CoraRedirection } from "../../../utils/HomeApis";
import "./style.css";

const Header = ({ className, text }) => {
  const siteRedirection = useSelector((state) => state.Home);
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={`header ${className}`}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
      <div className="heading2">
        I’m not ready to complete my certificate yet. I’d like to go back to the
        <button
          onClick={() =>
            CoraRedirection({
              status: "Taxable",
              homeData: siteRedirection,
              dispatch
            })
          }
          className="redirectCora"
        >
          Account Application
        </button>
      </div>
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Header.defaultProps = {
  className: "",
};

export default Header;
