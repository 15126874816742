import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import "./style.css";

const ErrorFallback = ({ error }) => {

  return (
    <div
      role="alert"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '90vh'
      }}
    >
      <div>
        <ErrorIcon style={{ fontSize: '90px', color: 'gray' }} />
      </div>
      <h1>Oops! Something went wrong.</h1>
      <div style={{ color: 'red',margin: '0 20%', 'text-align': 'center'}}>
        <span style={{ fontSize: '18px' }}>Error Details: </span>{' '}
        {error || error?.message}
      </div>
    </div>
  );
};
export default ErrorFallback;
