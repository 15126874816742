import { axiosInstance } from "../api/Axios";

export const uploadFile = async (acceptedFile, homeData) => {
  let formData = new FormData();
  formData.append("file", acceptedFile);
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

  const params = new URLSearchParams({
    email: homeData.email?.trim() || "",
    owner_email: homeData.owner_email?.trim() || "",
    are_you_non_profit: homeData.are_you_non_profit?.trim() || "",
    registered_tribal_member: homeData.registered_tribal_member?.trim() || "",
    ship_to_on_tribal_land: homeData.ship_to_on_tribal_land?.trim() || "",
    legal_name: homeData.legal_name?.trim() || "",
    trade_dba_name: homeData.trade_dba_name?.trim() || "",
    company_phone: homeData.company_phone?.trim() || "",
    bill_to_address_1: homeData.bill_to_address_1?.trim() || "",
    bill_to_city: homeData.bill_to_city?.trim() || "",
    bill_to_state: homeData.bill_to_state?.trim() || "",
    bill_to_zip: homeData.bill_to_zip?.trim() || "",
    style_of_business: homeData.style_of_business?.trim() || "",
    opco_number: homeData.opco_number?.trim() || "",
    cora_id: homeData.cora_id?.trim() || "",
    ship_to_address_1:
      homeData.shipping_address[0]?.ship_to_address_1?.trim() || "",
    ship_to_city: homeData.shipping_address[0]?.ship_to_city?.trim() || "",
    ship_to_state: homeData.shipping_address[0]?.ship_to_state?.trim() || "",
    ship_to_zip: homeData.shipping_address[0]?.ship_to_zip?.trim() || "",
    created_date: homeData.created_date || new Date().toJSON().slice(0, 10), //today date
    expiration_date:
      homeData.expiration_date || aYearFromNow.toJSON().slice(0, 10), ///today date +1 year
  }).toString();

  const url = `${homeData?.env?.base_url}/avalara/upload-document?${params}`;
  try {
    const response = await axiosInstance.request({
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    return response;
  } catch (error) {
    return error;
  }
};
